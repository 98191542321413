import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Banner from "../../components/pages/main/banner"
import GalleryComponent from "../../components/pages/features/gallery"
import FeaturesRow from "../../components/pages/features/row"
import FeaturesPricingCards from "../../components/pages/features/cards"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs'

const PortalFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Кабинет клиента', url: ''}
  ];
  return (
    <Layout page={"portal"}>
      <SEO
        title="Личные кабинеты | CRM-система учета для детских центров"
        keywords={[`зарплаты сотрудников`, `расчет зарплат педагогов`, `расчет зарплат администраторов`]}
        description="В CRM для детских и учебных центров Параплан есть Личные кабинеты учеников. Индивидуальное расписание, онлайн оплаты, домашние задания для каждого ученика. Облачная CRM Параплан подходит для детских центров, франшиз, танцевальных студий, языковых центров, учебных центров и спортивных школ."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"portal-beauty"}
        title='Оформите доступ к&nbsp;личным кабинетам'
        description='Расписание открытых записей, онлайн-оплата и запланированные посещения теперь под рукой у ваших клиентов.'
        color='#6D20D2'
        btnGreen={true}
        image={require("../../images/cover/portal.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
      <GalleryComponent
        title={"Личный кабинет для каждого клиента"}
        galleryView={"video"}
        data={[
          {
            name: "schedule",
            title: "Удобное расписание",
            content: "Клиенты могут проверить запланированные посещения в 2 клика и поставить напоминание о визите в CRM с кабинетом клиента.",
            image: require("../../images/screenshot/portal/portal_gallery_schedule@2x.png"),
            video: require("../../video/schedule.mp4"),
          },
          {
            name: "acquiring",
            title: "Онлайн-оплата",
            content: "Оплата услуг легко осуществляется через личный кабинет. Теперь не надо тратить время администратора и искать наличные.",
            image: require("../../images/screenshot/portal/portal_gallery_acquiring@2x.png"),
            video: require("../../video/acquiring.mp4"),
          },
          {
            name: "finances",
            title: "Финансовая прозрачность",
            content: "В личном кабинете клиенты видят историю платежей и неоплаченных посещений.",
            image: require("../../images/screenshot/portal/portal_gallery_finances@2x.png"),
            video: require("../../video/finances.mp4"),
          },
        ]}
      />
      {/*<FreeSummer topInd="60"/>*/}
      <Discount />
      <FeaturesRow
        features={[
          {
            name: "Проверка баланса клиента",
            text: "Положительный или отрицательный баланс доступен для просмотра и пополнения.",
            image: require("../../images/icons/portal_balance.svg"),
          },
          {
            name: "Клиент видит все свои визиты",
            text: "Клиент может просматривать состоявшиеся посещения салона или планировать новые.",
            image: require("../../images/icons/portal_attendance.svg"),
          },
          {
            name: "Отмена или перенос записи",
            text: "Все изменения в запланированных посещениях наглядно отражаются внутри кабинета.",
            image: require("../../images/icons/portal_cancellations.svg"),
          },
        ]} />
      <Banner/>
      <FeaturesPricingCards
        withMargins={true}
        title='Станьте ближе к своим клиентам!'
        subtitle='Стоимость функции "Личные кабинеты" вне зависимости от количества клиентов.' />
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent />
    </Layout>
  )
}

export default PortalFeaturePage
