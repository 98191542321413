import React from "react"
import styles from "./banner.module.scss"
import ScrollAnimation from "react-animate-on-scroll"
import PresentationOverlayService from "../../../../services/presentation-overlay-service"

const Banner = (withMargins) => (
    <div className={`${styles.banner} ${withMargins ? styles.withMargins : ''}`}>
        <div className={`container ${styles.bannerContainer}`}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>Получить обзор по заполненной демонстрационной системе</h2>
                <button className={styles.cta} onClick={() => PresentationOverlayService.open()}>
                    Оставить заявку
                </button>
                <ScrollAnimation animateIn={"animate-tada"} duration={1}>
                    <div className={styles.arrow} />
                </ScrollAnimation>
            </div>
        </div>
    </div>
)

export default Banner